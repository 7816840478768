import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = () => {
    return (
        <Layout>

            <Seo/>

            <section className="landing mod-dark-blue mod-basic">
                <div className="fw">
                    <div className="landing-intro">
                        <h1 className="mod-white">Privacy Policy</h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="fw">
                    <div>
                        <p>Venture Blue Ltd. (“We”) are committed to protecting and respecting your privacy.</p>
                        <p>This Policy (together with our terms of use on <a href="https://ventureblue.co.uk">https://ventureblue.co.uk</a> and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting <a href="https://ventureblue.co.uk">https://ventureblue.co.uk</a> you are accepting and consenting to the practices described in this Policy.</p>
                        <p>For the purpose of the General Data Protection Regulation 2018 (GDPR), the data controller is Venture Blue Ltd of V25 Victoria Gardens, Burgess Hill RH15 9NB.</p>
                        <p><strong>Information we may collect from you</strong></p>

                        <p>We may collect and process the following data about you:</p>

                        <p><strong>Information you give us</strong></p>
                        <p>You may give us information about you by filling in forms on <a href="https://ventureblue.co.uk">https://ventureblue.co.uk</a> (our site) or by corresponding with us by phone, e-mail, live chat, our social media channels or other means. This includes information you provide when you register to use our site, subscribe to our service, search for a product, place an order on our site, participate in discussion boards or other social media functions on our site, enter a competition, promotion or survey and when you report a problem with our site. The information you give us may include your name, address, email address and phone number, financial and credit card information, personal description and product or service photographs.</p>

                        <p><strong>Information we collect about you</strong></p>
                        <p>With regards to each of your visits to our site we may automatically collect the following information:</p>

                        <ul>
                            <li>technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</li>
                            <li>information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</li>
                        </ul>

                        <p><strong>Information we receive from other sources</strong>
                        </p>
                        <p>We may receive information about you if you use any of the other websites we operate or the other services we provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this site. We are also working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them.</p>
                        <p><strong>Cookies</strong></p>
                        <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                        <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                        <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                        <p>Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                        <p>Below are the types of cookies that we use.</p>
                        <p><em>Functional Cookies</em> – These cookies make certain parts of our site function. For saving your cart between pages or keeping you logged in.</p>
                        <p><em>Analytical Cookies</em> – Using tracking cookies allows us to improve your experience of the site. A large number of decisions about how we improve the website are made using these cookies. All data is anonymous.</p>
                        <p><em>Third Party Cookies</em> – We occasionally team up with other systems and sites to bring you features such as product videos and mailing list signups. You may be sent a cookie from one of these companies when using the functionality we use from their service.</p>
                        <p><em>Advertising Cookies</em> – Venture Blue Ltd uses the Google Content Network from time to time to show you future adverts about our products. Where this is used, Google will use a cookie to show you those adverts in the future. You can set the type of adverts that Google shows you in your Google account.
                        </p>
                        <p><strong>Information you give to us.</strong></p>
                        <p>We will use this information:</p>
                        <p>to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;</p>
                        <p>to provide you with information about goods or services we feel may interest you. If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale or negotiations of a sale to you. If you are a new customer, we will contact you by electronic means only if you have consented to this.</p>
                        <p>to notify you about changes to our service;</p>
                        <p>to ensure that content from our site is presented in the most effective manner for you and for your computer.</p>
                        <p>Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>

                        <p><strong>Information we collect about you.</strong></p>
                        <p>We share your information internally within Venture Blue Ltd.;</p>
                        <p>to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</p>
                        <p>to improve our site to ensure that content is presented in the most effective manner for you and for your computer and/or mobile device;</p>
                        <p>to allow you to participate in interactive features of our service, when you choose to do so;</p>
                        <p>as part of our efforts to keep our site safe and secure;</p>

                        <p>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;</p>
                        <p><strong>Disclosure of your Information</strong></p>
                        <p>We may share your personal information with any member of Venture Blue Ltd., which means our subsidiaries, our ultimate holding company and its subsidiaries.</p>
                        <p>We may share your information with selected third parties including:</p>

                        <ul>
                            <li>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you;</li>
                            <li>Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 400 men aged under 25 have clicked on their advertisement on any given day). We may also use such aggregate information to help advertisers reach the kind of audience they want to target (for example, women in BN1). We may make use of the personal data we have collected from you to enable us to comply with our advertisers’ wishes by displaying their advertisement to that target audience;</li>
                            <li>Analytics and search engine providers that assist us in the improvement and optimisation of our site;</li>
                            <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;</li>
                            <li>If Venture Blue Ltd. or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;</li>
                            <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our <a href="https://ventureblue.co.uk/terms-conditions">terms of use</a> and other agreements; or to protect the rights, property, or safety of Venture Blue Ltd, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
                        </ul>
                        <p>We may share aggregated non-personally identifiable information externally: We do also share certain information which, whilst not personally identifiable information relating to you, does include information that relates to your usage of our products, sites and services, aggregated together with the same information from other users. For example, customer order numbers.</p>
                        <p><strong>Where we store your Personal Data</strong></p>
                        <p>The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</p>
                        <p>All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                        <p><strong>How long do we keep your information for?</strong>
                        </p>
                        <p>We retain the information you provide either while your account is in existence, or as needed to be able to provide the services to you, or (in the case of any contact you may have with our Customer Care team) for as long as is necessary to provide support-related reporting and trend analysis only.</p>
                        <p>If legally required or if it is reasonably necessary to meet regulatory requirements, resolve disputes, prevent fraud and abuse, or enforce our Terms and Conditions, we may also retain some of your information for a limited period of time as required, even after you have closed your account or it is no longer needed to provide the services to you.</p>
                        <p><strong>Your Rights under the GDPR include:</strong></p>
                        <p><strong>Right to be informed</strong></p>
                        <p>We seek to be transparent by providing this Privacy Policy which clearly explains how we use your personal data.</p>
                        <p><strong>Right of access</strong></p>
                        <p>You have the right to access information held about you. Any access request must be processed without delay, free of charge and at the latest within one month of receipt.</p>
                        <p>We reserve to right to extend the period of compliance by a further two months where requests are complex or numerous. If this is the case, we will inform you within one month of the receipt of the request and explain why the extension is necessary.</p>
                        <p><strong>Right to rectification</strong></p>
                        <p>You have the right to have your personal data rectified if it is inaccurate or incomplete.</p>
                        <p><strong>Right to erasure (also known as ‘right to be forgotten’)</strong></p>
                        <p>You have the right to request the deletion or removal of personal data where there is no compelling reason for its continued processing. Please note, there are some specific circumstances where the right to erasure does not apply.</p>
                        <p><strong>Right to restrict processing</strong></p>
                        <p>You have the right to request the restriction or suppression of your personal data.</p>
                        <p><strong>Right to object</strong></p>
                        <p>You have the right to object to data processing based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling); direct marketing (including profiling); and processing for purposes of scientific/historical research and statistics.</p>
                        <p><strong>Changes to our Privacy Policy</strong></p>
                        <p>Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy Policy. This Policy was last updated 17/08/20.</p>
                        <p><strong>Contact</strong></p>
                        <p>Questions, comments and requests regarding this Privacy Policy are welcomed and should be addressed to Venture Blue Ltd, V25 Victoria Gardens, Burgess Hill RH15 9NB. You can call us on 01273 947300.</p>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Privacy