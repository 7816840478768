import * as React from "react"
import { Link } from 'gatsby'
import PropTypes from "prop-types"
import { useEffect } from "react"

const Layout = ({ children }) => {

  useEffect(() => {

    var loader = document.getElementById('page-loader');
    loader.classList.add('done');
    setTimeout(()=>{ loader.parentNode.removeChild(loader) }, 2000);

  }, [])

  return (
    <div>

      <div id="page-loader"/>

      <main>{children}</main>

      <footer>
        © {new Date().getFullYear()} <a href="https://retain.digital/" target="_blank" rel="noreferrer">Retain Digital Ltd.</a> All rights reserved
        <div>
          <a href="/">Home</a>
          <Link to="/cookies">Cookies</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-conditions">Terms & Conditions</Link>
        </div>
      </footer>

    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout